import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

import { useQuery } from '@tanstack/react-query';
import { useProject } from './ProjectContext';
import GraphqlService from '../service/graphqlService';

const AnalyticsHandler = () => {
  const { project } = useProject();

  const getProjects = async () => GraphqlService.getProjects();

  const {
    data: projectsData,
  } = useQuery(['getProjects'], () => getProjects(), {
    cacheTime: 1000,
  });

  useEffect(() => {
    const initAnalytics = async () => {
      try {
        // Initialize dataLayer if it doesn't exist
        if (!window.dataLayer) {
          window.dataLayer = [];
        }

        const authUser = await Auth.currentAuthenticatedUser();

        // Push both user email and project data
        const obj = {
          event: 'user_identified',
          selected_project: project,
          user: {
            id: authUser?.attributes?.id,
            email: authUser?.attributes?.email,
          },
          projects: projectsData,
        };

        window.dataLayer.push(obj);
        console.log('Analytics data pushed:', obj);
      } catch (error) {
        console.error('Error getting user for analytics:', error);
      }
    };

    if (project?.code) {
      initAnalytics();
    }
  }, [project?.code, project?.name, projectsData]);

  return null;
};

export default AnalyticsHandler;

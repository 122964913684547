const changelog = [
  {
    version: '3.14.0',
    releaseDate: '11.02.2025',
    new: [
      {
        title: 'Fine-grained permissions',
        description: 'The implementation of this feature enhances security by restricting developers from deploying firmware to specific devices. This ensures new firmware is validated on a limited scope, minimizing the risk of widespread device malfunctions. Additionally, telemetry access is restricted to maintain consistency and protect sensitive data.',
      },
    ],
    updated: [],
    fix: [],
    urlDocumentation: 'https://nestle.atlassian.net/wiki/spaces/DIIS/pages/370936723/IoT+Portal+3.14.0',
  },
  {
    version: '3.13.0',
    releaseDate: '20.01.2025',
    new: [
      {
        title: 'IoT Platform Health',
        description: 'Admin users now have the capability to monitor the Platform Health directly within the Portal.',
      },
    ],
    updated: [
      {
        title: 'ServiceNow form',
        description: 'The "New IoT Asset Version Analysis and Asset Deployment" ServiceNow form has been enhanced and is now available in the Service Request menu.',
      },
      {
        title: 'Incident reporting',
        description: 'A "Raise an Incident" link has been added to the Service Request menu, allowing users to easily redirect to ServiceNow to raise an incident directly from the Portal.',
      },
    ],
    fix: [],
  },
  {
    version: '3.12.0',
    releaseDate: '17.12.2024',
    new: [
      {
        title: 'Simulated Client as a Service',
        description: 'Users can now simulate MQTT messages from device to cloud. This new feature offers a great support to the development integration activities.',
      },
    ],
    updated: [],
    fix: [
      {
        title: 'General',
        description: 'Bug Fixes',
      },
    ],
  },
  {
    version: '3.11.0',
    releaseDate: '27.11.2024',
    new: [],
    updated: [
      {
        title: 'Error messages',
        description: 'Conveying of error messages from the platform APIs is update all across the Portal.',
      },
    ],
    fix: [
      {
        title: 'Bundles',
        description: 'Bug Fixes in Bundles',
      },
      {
        title: 'Waves',
        description: 'Bug Fixes in Waves',
      },
    ],
  },
  {
    version: '3.10.0',
    releaseDate: '30.10.2024',
    new: [
      {
        title: 'Waves',
        description: 'A wave entity is created for deployment of assets or asset bundles which increases traceability. User is able to delete all deployments associated with the wave in one transaction.',
      },
    ],
    updated: [],
    fix: [],
  },
];

export default changelog;
